<template>
  <!-- class root-splash is defined in index.html -->
  <img
    v-if="!hasView"
    class="root-splash root-splash-second"
    src="/api/logo/"
  >
  <LoadingProgress />
  <RouterView />
</template>

<script>
/*
 * Root component
 */

import { onErrorCaptured } from 'vue'

import { useActivitiesUpdater, useActivitySeriesUpdater, useActivityTypeUpdater } from '@/activities/queries'
import { useAdminSettingsUpdater } from '@/admin/queries'
import { useAgreementsUpdater } from '@/agreements/queries'
import { useApplicationsUpdater } from '@/applications/queries'
import { useAuthUserUpdater } from '@/authuser/queries'
import { useSettingsUpdater } from '@/base/queries'
import { useRoutingLogic, useCheckResponseAuthStatus } from '@/base/services'
import { useWebsocket } from '@/base/services/websocket'
import { usePerformance } from '@/boot/performance'
import { karrotPlugins } from '@/boot/plugins'
import { useGroupDetailUpdater } from '@/group/queries'
import { useGroupInfoUpdater } from '@/groupInfo/queries'
import { useIssuesUpdater } from '@/issues/queries'
import { useRoomListUpdater } from '@/meet/queries'
import { useConversationUpdater, useMessageUpdater } from '@/messages/queries'
import { useDetailService } from '@/messages/services'
import { useNotificationsUpdater } from '@/notifications/queries'
import { useOffersUpdater } from '@/offers/queries'
import { usePlacesUpdater } from '@/places/queries'
import { usePollsUpdater } from '@/polls/queries'
import { useResetSidenavFunctions } from '@/sidenav/composables'
import { useStatusUpdater } from '@/status/queries'
import { useUsersUpdater } from '@/users/queries'
import { useClearDataOnLogout, useTitleStatus } from '@/utils/composables'
import { captureException } from '@/utils/errors'
import { showToast } from '@/utils/toasts'

import LoadingProgress from '@/topbar/components/LoadingProgress.vue'

export default {
  components: {
    LoadingProgress,
  },
  setup () {
    if (import.meta.env.MODE === 'cordova') {
      // Only load the dependencies if actually in cordova mode
      const { useCordova } = require('@/utils/cordova')
      useCordova()
    }
    useWebsocket()

    useRoutingLogic()
    useCheckResponseAuthStatus()

    // Websocket updaters
    useAgreementsUpdater()
    usePollsUpdater()
    useAuthUserUpdater()
    useApplicationsUpdater()
    useUsersUpdater()
    usePlacesUpdater()
    useOffersUpdater()
    useStatusUpdater()
    useActivitiesUpdater()
    useActivitySeriesUpdater()
    useActivityTypeUpdater()
    useConversationUpdater()
    useMessageUpdater()
    useGroupInfoUpdater()
    useGroupDetailUpdater()
    useNotificationsUpdater()
    useIssuesUpdater()
    useRoomListUpdater()
    useSettingsUpdater()
    useAdminSettingsUpdater()

    // Utilities
    usePerformance()
    useTitleStatus()
    useClearDataOnLogout()
    useDetailService() // needs to be initialized as it uses router hooks

    // This catches things that otherwise don't seem to be printed anywhere in the console
    // It doesn't stop the error propagating elsewhere, it's just seeing it, so nice and neutral
    onErrorCaptured(error => {
      console.error(error)
      showToast({
        message: error.message,
        config: {
          icon: 'priority_high',
          color: 'warning',
        },
      })
    })

    useResetSidenavFunctions()

    for (const plugin of karrotPlugins) {
      if (plugin.setup) {
        try {
          plugin.setup()
        }
        catch (error) {
          console.error('failed to setup plugin', plugin, error)
          captureException(error)
        }
      }
    }
  },
  computed: {
    hasView () {
      const firstMatched = this.$route.matched.length > 0 && this.$route.matched[0]
      if (!firstMatched) return
      return Boolean(firstMatched.components.default)
    },
  },
  mounted () {
    const el = document.querySelector('.root-splash-first')
    if (el) el.remove()
  },
}
</script>

<style lang="sass" scoped>
.root-splash-second
  opacity: 1
  animation: fadein 2s
@keyframes fadein
  from
    opacity: 0.3

  to
    opacity: 1
</style>
