import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { unref } from 'vue'

import api from '@/admin/api/settings'
import { useSocketEvents } from '@/utils/composables'

const QUERY_KEY_BASE = 'settings'
export const queryKeySettings = include => [QUERY_KEY_BASE, 'settings', include]
export const queryKeySettingsSchema = () => [QUERY_KEY_BASE, 'schema']

export function useAdminSettingsUpdater () {
  const queryClient = useQueryClient()
  const { on } = useSocketEvents()
  on(
    'config:updated',
    () => {
      queryClient.refetchQueries({
        queryKey: [queryKeySettings()],
      })
    },
  )
}

export function useSettingsQuery ({ include = 'db' } = {}) {
  const query = useQuery({
    queryKey: queryKeySettings(include),
    queryFn: () => api.settings(unref(include)),
  })
  return {
    ...query,
    settings: query.data,
  }
}

export function useSettingsSchemaQuery () {
  const query = useQuery({
    queryKey: queryKeySettingsSchema(),
    queryFn: () => api.schema(),
  })
  return {
    ...query,
    schema: query.data,
  }
}
