import axios from '@/base/api/axios'

export default {
  async settings (include = 'db') {
    return (await axios.get('/api/admin/settings/', { params: { include }, noKeyConversion: true })).data
  },
  async schema () {
    return (await axios.get('/api/admin/settings/schema/', { noKeyConversion: true })).data
  },
  async validate (data) {
    return (await axios.post('/api/admin/settings/validate/', data, { noKeyConversion: true })).data
  },
  async save (data) {
    return (await axios.post('/api/admin/settings/', data, { noKeyConversion: true })).data
  },
  async generateVapidKeypair () {
    return (await axios.get('/api/admin/settings/generate/vapid-keypair/', { noKeyConversion: true })).data
  },
}
